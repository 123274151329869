
.Search {
  border: 1px solid rgb(231, 225, 236);
  border-radius: 4px;
  height: 40px;
  box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px inset;
  background: rgb(255, 255, 255);
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-bottom: 16px;

  label {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px;
    padding-left: 8px;
    color: rgb(149, 133, 163);
  }

  input {
    color: rgb(74, 62, 86);
    background: transparent;
    border: 0px;
    outline: none;
    font-size: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0px 0px 0px 8px;
  }
}
