*,
:after,
:before {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
}

body {
  font-family: Rubik, Avenir Next, Helvetica Neue, sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #2f2936;
  background: #fbfbfc;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  min-height: 100vh;
}

html,
body,
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

body {
  padding-left: 220px;
}

a,
a:hover {
  text-decoration: none;
}

a {
  color: #3b6ecc;
  -webkit-transition: color 0.2s linear;
  -o-transition: color linear 0.2s;
  transition: color 0.2s linear;
  cursor: pointer;

  &:hover {
    color: #2c58a8;
  }
}

pre {
  word-break: break-all;
  white-space: pre-wrap;
  padding: 10px;
}

code,
pre {
  border: 0;
  background-color: #f7f8f9;
  color: #2f2936;
  border-radius: 3px;
}

code,
kbd,
pre,
sample {
  font-family: 'IBM Plex Mono', Consolas, Courier New, monospace;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

.Text-right {
  text-align: right;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

hr {
  height: 1px;
  width: 100%;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px;
  margin: 0.5rem 0 2rem;
}
