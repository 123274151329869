.Input {
  &-container {
    select {
      flex: 1;
      font-size: 1rem;
      line-height: 1.5;
      box-sizing: border-box;
      color: rgb(32, 34, 43);
      font-family: inherit;
      width: 100%;
      // background-color: rgb(255, 255, 255);
      // border-radius: 4px;
      border-width: 0px;
      // border-style: solid;
      // border-color: rgb(227, 230, 236);
      border-image: initial;
      padding: 8px;

      &:focus {
        border-color: rgb(91, 83, 255);
        outline: none;
        box-shadow: rgba(91, 83, 255, 0.1) 0px 0px 8px 0px;
      }
    }
  }
}
