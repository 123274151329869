.Table {
  position: inherit;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, auto));
  box-sizing: border-box;
  border-collapse: collapse;
  margin: 0px;
  z-index: 5;
  overflow-x: scroll;

  th {
    position: relative;
    height: 45px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-width: 24px;
    padding: 0px 16px;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    background-color: rgb(250, 249, 251);
    color: rgb(100, 85, 116);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    user-select: none;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  tbody {
    tr {
      td {
        background-color: rgb(255, 255, 255);
      }

      &:hover {
        td {
          background-color: rgb(250, 249, 251);
          color: rgb(100, 85, 116);
        }
      }

      &:first-child {
        td {
          border-top: 1px solid rgb(198, 190, 207);
        }
      }
    }
  }

  td {
    min-width: 0px;
    min-height: 40px;
    padding: 8px 16px;
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(231, 225, 236);
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:first-child {
      padding: 8px 0px 8px 20px;
    }
  }

  th {
    a,
    div {
      line-height: 1.1;
      color: inherit;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  td {
    a,
    div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
