.Sidebar {
  width: 220px;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  background: linear-gradient(
    294.17deg,
    rgb(47, 25, 55) 35.57%,
    rgb(69, 38, 80) 92.42%,
    rgb(69, 38, 80) 92.42%
  );
  color: rgb(149, 134, 165);

  &-section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 8px 0px;
    padding: 0px 19px;
  }
}
