.Button {
  color: #493e54;
  background: #fff;
  background-image: -webkit-linear-gradient(top, #fff, #fcfbfc);
  background-image: -o-linear-gradient(top, #fff 0, #fcfbfc 100%);
  background-image: linear-gradient(180deg, #fff 0, #fcfbfc);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff", endColorstr="#fffcfbfc", GradientType=0);
  background-repeat: repeat-x;
  border-color: #c1b8ca;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 3px;
  font-weight: 600;
  padding: 8px 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  color: #493e54;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffffff", endColorstr="#fffcfbfc", GradientType=0);
  background-repeat: repeat-x;
  border-color: #c1b8ca;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.03);
  font-size: 12px;
  padding: 4px 9px;
  outline: 0;

  &-secondary,
  &-icon {
    background: #fff;
    background-image: -webkit-linear-gradient(top, #fff, #fcfbfc);
    background-image: -o-linear-gradient(top, #fff 0, #fcfbfc 100%);
    background-image: linear-gradient(180deg, #fff 0, #fcfbfc);

    &:hover {
      color: #2f2936;
      border-color: #afa3bb;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
    }

    &:active {
      background-image: none;
      color: #2f2936;
      background-color: #f7f8f9;
      border-color: #afa3bb;
      box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
    }
  }

  &-large,
  &-primary {
    display: inline-block;
    line-height: 1 !important;
    border-radius: 3px !important;
    text-transform: none !important;
    font-weight: 600;
    font-size: 12px !important;
    cursor: pointer;
    padding: 9px 12px !important;
  }

  &-primary {
    color: rgb(255, 255, 255);
    background-color: rgb(108, 95, 199);
    border: 1px solid rgb(61, 50, 142);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &-icon {
    min-height: 28px;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
