.Input {
  display: block;
  text-align: left;

  &-label {
    font-size: 18px;
    margin-bottom: 8px;
    color: rgb(43, 29, 56);
  }

  &-error {
    color: red;
    margin-left: 0.25rem;
    font-weight: 300;
  }

  &-required {
    color: #bf0909;
  }

  &-container {
    color: rgb(43, 29, 56);
    width: 100%;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(198, 190, 207);
    border-radius: 4px;
    box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px inset;
    transition: border 0.1s linear 0s;
    resize: vertical;
    padding: 5px 10px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    input {
      border: 0px;
      outline: 0px;
      flex: 1 1 0%;
    }
  }
}
