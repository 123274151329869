.Page {
  display: flex;
  flex: 1;

  &-title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 22px;
    color: rgb(74, 62, 86);
    height: 40px;
    margin-bottom: 8px;
  }

  &-full {
    display: flex;
    flex: 1;
  }

  &-contents {
    flex: 1;
    background: white;
    margin: -20px -30px;
    padding: 20px 30px;
  }

  &-header {
    position: relative;
    margin: 0 0 20px;
    clear: both;

    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -1px;
      left: -40px;
      right: -40px;
      height: 1px;
      background: #e2dee6;
    }

    h3 {
      color: #161319;
      font-size: 22px;
      margin: 0 0 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.1;
    }

    &-actions {
      margin-top: 15px;
      display: flex;
      grid-gap: 5px;
    }
  }

  &-header-nav {
    display: flex;
    list-style: none;
    border-bottom: 0;
    padding: 0;
    margin: 20px 0 0;

    a {
      margin-bottom: -1px;
      margin-right: 20px;
      display: block;
      font-size: 15px;
      padding: 0 0 10px;
      border: 0;
      background: none;
      color: #7c6a8e;
      min-width: 30px;
      text-align: center;

      &.active {
        cursor: pointer;
        border-bottom: 4px solid #6c5fc7;
        background: none;
        color: #161319;
        font-weight: 400;
      }
    }
  }
}
