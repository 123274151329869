.Form {
  display: grid;
  grid-gap: 1rem;

  &-group {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
  }
}
