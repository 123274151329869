.DataSection {
  padding: 20px 30px 0px 40px;
  border-top: 1px solid rgb(231, 225, 236);

  + .DataSection {
    margin-top: 30px;
  }

  &-header {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    margin: 0 0 8px;

    h3 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      color: rgb(149, 133, 163);
      padding: 6px 0px;
      text-transform: uppercase;
      margin: 0 0 8px;
    }
  }
}

.DataSection-row {
  display: flex;
  align-items: center;
}

.DataSection-key {
  font-weight: 600;
  font-size: 13px;
  width: 175px;
  max-width: 175px;
  word-wrap: break-word;
  padding: 10px 15px 10px 0 !important;
  line-height: 1.4 !important;
}

.DataSection-value {
  flex: 1;
  display: flex;
  color: #4e3fb4;
  padding: 2px 4px;

  pre {
    color: rgb(78, 63, 180);
    flex: 1;
    box-sizing: border-box;
    white-space: pre-wrap;
    margin-top: 2px;
    margin-bottom: 2px;
    word-break: break-word;
    padding: 8px 10px;
    font-size: 12px;

    &.flat {
      white-space: normal;
    }
  }
}
