@import '../styles/media';

.Main {
  overflow: hidden;
  flex: 1;
  display: flex;
}

.Main-content {
  display: flex;
  flex: 1;
}

.Page-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 16px 30px 20px;
}
