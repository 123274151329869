.Panel {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(198, 190, 207);
  box-shadow: rgba(37, 11, 54, 0.04) 0px 2px 0px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &-header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 16px;
  }
}
