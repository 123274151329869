.Sidebar-navigation {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1rem 0;

  a {
    display: flex;
    color: inherit;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    line-height: 32px;
    height: 34px;
    flex-shrink: 0;
    transition: color 0.15s linear 0s;

    &.active {
      color: white;
    }
  }
}
