.TeamPage {

}

.EventDetails {
  margin: -20px -40px;
}

.EventMembers-header {
  margin-bottom: 1rem;
  text-align: right;
}

.Table {
  max-width: calc(100vw - 220px - 30px - 30px);
}

.EventResults-header {
  margin-bottom: 1rem;
  display: flex;

  button {
    margin-left: 0.5rem;
  }

  .spacer {
    flex: 1;
  }

  .Search {
    margin: 0;
    height: 2rem;
    flex: 0;
    min-width: 300px;

    svg {
      height: 14px;
    }

    input {
      height: 2rem;
      line-height: 1rem;
    }
  }
}
