.FileInput {
  &-preview {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}
